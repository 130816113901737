import type { AppEssentials } from '@wix/fe-essentials-viewer-platform'
import { ILocationManager, ModuleLoader } from '@wix/thunderbolt-symbols'
import { PLATFORM_API_PROVIDER, MODULE_LOADER, LOCATION_MANAGER } from './moduleNames'

const AUTO_FEM_URL = 'https://static.parastorage.com/services/auto-frontend-modules/1.20.0/node/auto-frontend-modules.umd.min.js'

export interface PlatformApiProvider {
	getPlatformApi: (moduleName: string) => Promise<any>
}

export interface PlatformApiFactory {
	initPlatformApiProvider: (appEssentials: AppEssentials) => PlatformApiProvider
}

interface PlatformApiFactoryArgs {
	appEssentials: AppEssentials
}

interface AutoFemRegistry {
	namespacesSdkFactory: () => Record<string, (args: PlatformApiFactoryArgs) => Promise<any>>
}

const PlatformApiFactoryProvider = (moduleLoader: ModuleLoader, locationManager: ILocationManager): PlatformApiFactory => {
	const shouldInitPlatformApiProvider = locationManager.getSearchParams()?.initPlatformApiProvider === 'true'

	const promise = shouldInitPlatformApiProvider ? moduleLoader.loadModule<AutoFemRegistry>(AUTO_FEM_URL) : new Promise<AutoFemRegistry>((res) => res((undefined as unknown) as AutoFemRegistry))

	return {
		initPlatformApiProvider: (appEssentials: AppEssentials) => {
			async function getPlatformApi(moduleName: string) {
				if (!shouldInitPlatformApiProvider) {
					throw new Error('PlatformApiProvider was not initialized, pass query param "initPlatformApiProvider=true"')
				}

				const namespaces = await promise.then(({ namespacesSdkFactory }) => namespacesSdkFactory())

				return namespaces[moduleName]({ appEssentials })
			}

			return {
				getPlatformApi,
			}
		},
	}
}

export default {
	factory: PlatformApiFactoryProvider,
	deps: [MODULE_LOADER, LOCATION_MANAGER],
	name: PLATFORM_API_PROVIDER,
}
